import React from 'react'
import Navbar from '../Navbar'
import Topbar from '../Topbar'

import logo from '../../assets/images/logo-fig.png'
import valor1 from '../../assets/images/valores/etica.png'
import valor2 from '../../assets/images/valores/integridad.png'
import valor3 from '../../assets/images/valores/lealtad.png'
import valor4 from '../../assets/images/valores/respeto.png'
import valor5 from '../../assets/images/valores/excelencia.png'
import Footer from '../Footer'

export default function About() {
    return (
        <div id='about' className='page'>
            <div className='top'>
                <Topbar />
                <Navbar />
                <div className='top-text-container'>
                    <div className='top-text'>
                        <h1>NOSOTROS</h1>
                    </div>
                </div>
            </div>

            <section id='about-1'>
                <div className='content'>
                    <div className='w20 flexrow center pad5 marg5'>
                        <img src={logo} alt='logo'/>
                    </div>
                    <div className='w80 flexrow center pad5 marg5 text-j'>
                        <p className='text'>PM{'&'}H CONSULTING SAC es una empresa peruana líder en procesos metalúrgicos y de innovación tecnológica, basada en la amplia experiencia en la industria minera de su fundador Percy Mayta Huayta, trabajando en países como: Perú, Chile, Argentina, USA y Republica Democrática del Congo (África). </p>
                    </div>
                </div>
            </section>
            <section id='about-3'>
                <div className='title-container'>
                    <h2 className='title'>PRINCIPALES VALORES</h2>
                </div>
                <div className='content'>
                    <div className='flexcol marg5'>
                        <img src={valor1} alt='valor-1'/>
                        <h4>Conducta<br/>Ética</h4>
                    </div>
                    <div className='flexcol marg5'>
                        <img src={valor2} alt='valor-2'/>
                        <h4>Integridad<br/>Profesional</h4>
                    </div>
                    <div className='flexcol marg5'>
                        <img src={valor3} alt='valor-3'/>
                        <h4>Lealtad<br/>Profesional</h4>
                    </div>
                    <div className='flexcol marg5'>
                        <img src={valor4} alt='valor-4'/>
                        <h4>Respeto a las personas comunidades y el medio ambiente</h4>
                    </div>
                    <div className='flexcol marg5'>
                        <img src={valor5} alt='valor-5'/>
                        <h4>Excelencia en el servicio entregado</h4>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
