import React from 'react'
import ReactDom from 'react-dom'

import './modal.css'

export default function Modal({open, close, children}) {
    if(!open) return null
    return ReactDom.createPortal(
        <>
            <div className="overlay" onClick={close}></div>
            <div className="modal">
                <i id="close-modal" className="fas fa-window-close" onClick={close}></i>
                {children}
            </div>
        </>,
        document.getElementById('portal')
    )
}