import React from 'react'
import logo from '../assets/images/logo-c.png'

export default function Topbar() {
    return (
        <div id='topbar'>
            <a href='/'><img src={logo} alt="pmh-text-logo"/></a>
            <button className='button login-button'>INICIAR SESIÓN</button>
        </div>
    )
}
