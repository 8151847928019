import React from 'react'
import {Routes, Route} from 'react-router-dom'

import Home from '../components/pages/Home'
import About from '../components/pages/About'
import Services from '../components/pages/Services'
import Contact from '../components/pages/Contact'
import Publications from '../components/pages/Publications'

export default function PageRoute() {
    return (
        <Routes>
            <Route exact path="/" element={<Home /> }/>
            <Route exact path='/nosotros' element={<About />} />
            <Route exact path='/servicios' element={<Services />} />
            <Route exact path="/publicaciones" element={<Publications /> }/>
            <Route exact path='/contacto' element={<Contact />} />
        </Routes>
    )
}
