import {BrowserRouter as Router} from 'react-router-dom'
import PageRoute from './router/PageRoute'
import './App.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLocationDot, faPhone, faEnvelope, faClock, faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'

library.add(faLocationDot, faPhone, faEnvelope, faClock, faArrowUpRightFromSquare)

function App() {
  
  return (
    <Router>
      <div className="App">
        <PageRoute />
      </div>
    </Router>
  );
}

export default App;
