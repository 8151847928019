import React from 'react'
import {Link} from 'react-router-dom'

export default function Navbar() {
    return (
        <div id="navbar">
            <ul>
                <li><Link to="/">INICIO</Link></li>
                <li><Link to="/nosotros">NOSOTROS</Link></li>
                <li><Link to="/servicios">SERVICIOS</Link></li>
                <li><Link to="/publicaciones">PUBLICACIONES</Link></li>
                <li><Link to="/contacto">CONTÁCTANOS</Link></li>
            </ul>
        </div>
    )
}
