import React from 'react'
import Navbar from '../Navbar'
import Topbar from '../Topbar'

import remote from '../../assets/images/remoto.png'
import Footer from '../Footer'

export default function Services() {
    return (
        <div id='services' className='page'>
            <div className='top'>
                <Topbar />
                <Navbar />
                <div className='top-text-container'>
                    <div className='top-text'>
                        <h1>SERVICIOS</h1>
                    </div>
                </div>
            </div>
            <section id='services-1'>
                <div className='title-container'>
                    <h2 className='title'>PRINCIPALES SERVICIOS</h2>
                </div>
                <div className='content'>
                    <div className='card'>
                        <h3>Optimización Operacional de Plantas de Hidrometalurgicas de cobre (Lixiviación, Extracción por solventes, EW)</h3>
                    </div>
                    <div className='card'>
                        <h3>Optimización Operacional de Plantas de Lixiviación de oro y plata</h3>
                    </div>
                    <div className='card'>
                        <h3>Optimización Operacional de Plantas Concentradoras</h3>
                    </div>
                    <div className='card'>
                        <h3>Soporte Metalúrgico y de Control de Procesos en Plantas de Procesamiento de Minerales e Hidrometalúrgicas</h3>
                    </div>
                    <div className='card'>
                        <h3>Soporte de Procesos en el desarrollo de Proyectos: Nuevos y de Mejoras Operacionales</h3>
                    </div>
                    <div className='card'>
                        <h3>Auditorías operacionales orientados a detectar oportunidades de mejoras para incrementar la productividad de las operaciones</h3>
                    </div>
                    <div className='card'>
                        <h3>Cursos Prácticos de procesos para supervisores y operadores</h3>
                    </div>
                    <div className='card'>
                        <h3>Diseño, ejecución y evaluación de Pruebas Metalúrgicas para desarrollo de proyectos y optimización de plantas</h3>
                    </div>
                </div>
            </section>

            <section id='services-2'>
                <div className='title-container'>
                    <h2 className='title'>MONITOREO ONLINE DE PLANTAS DE PROCESOS METALÚRGICOS</h2>
                </div>
                <div className='content'>
                    <div className='flexcol w50 pad5 text-j'>
                        <p className='text marg5'>Centro de diagnóstico y monitoreo de las variables “críticas” de la operación a través de un seguimiento de la operación 24x7</p>
                        <p className='text marg5'>Profesionales disponibles las 24 horas del día, los 365 días del año para monitorear las variables “Criticas” de la operación de plantas</p>
                        <p className='text marg5'>Pionero en la implementación de servicios online de monitoreo de procesos de las Plantas de Procesamiento de Minerales e Hidrometalúrgicas, conducentes a la optimización de las operaciones basadas en la disminución de la variabilidad de los parámetros operativos. Realizando las cosas básicas bien hechas</p>
                    </div>
                    <div><img src={remote} alt='monitoreo'/></div>
                </div>
            </section>
            
            <Footer />
        </div>
    )
}