import React, {useState} from 'react'
import axios from 'axios'
import Navbar from '../Navbar'
import Topbar from '../Topbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Msg, {showMsgInSeconds } from '../msg/Msg'
import { isEmail, isEmpty, isLength } from '../utils/validation'
import Footer from '../Footer'

const msgDuration = 5;

const initial = {
    name: '',
    email: '',
    phone: '',
    text: '',
}

const initialMsg = {
    err: '',
    success: ''
}

export default function Contact() {
    const [data, setData] = useState(initial)
    const {name, email, phone, text} = data
    const [nameMsg, setNameMsg] = useState(initialMsg)
    const [emailMsg, setEmailMsg] = useState(initialMsg)
    const [phoneMsg, setPhoneMsg] = useState(initialMsg)
    const [textMsg, setTextMsg] = useState(initialMsg)
    const [sentMsg, setSentMsg] = useState(initialMsg)

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value})
        setNameMsg(initialMsg)
        setEmailMsg(initialMsg)
        setPhoneMsg(initialMsg)
        setTextMsg(initialMsg)
    }

    const saveContact = async(e) => {
        e.preventDefault()
        if(validation()){
            setSentMsg({err: '', success: 'Enviando ...'})
            try {
                const res = await axios.post('/api/contact-save' , {
                    name, email, phone, text
                })
                showMsgInSeconds(setSentMsg, {err: '', success: res.data.msg}, msgDuration)
            } catch (err) {
                err.response.data.msg &&
                showMsgInSeconds(setSentMsg, {err: 'Error: Refresque(F5) y vuelva a intentarlo', success: ''}, msgDuration*3) 
            }
        }
    }

    const validation = () => {
        let error = false
        if(isEmpty(name.trim())) {
            showMsgInSeconds(setNameMsg, {err: 'Coloque su nombre', success: ''}, msgDuration)
            error = true
        } else if (isLength(name.trim(), 2)) {
            showMsgInSeconds(setNameMsg, {err: 'Debe tener al menos 2 caracteres', success: ''}, msgDuration)
            error = true
        }
        if(!isEmail(email)) {
            showMsgInSeconds(setEmailMsg, {err: 'El email es inválido', success: ''}, msgDuration)
            error = true
        }
        if(isEmpty(phone.trim())) {
            showMsgInSeconds(setPhoneMsg, {err: 'Coloque su número de celular', success: ''}, msgDuration)
            error = true
        } else if(phone.trim().length !== 9) {
            
            showMsgInSeconds(setPhoneMsg, {err: 'Debe tener 9 caracteres', success: ''}, msgDuration)
            error = true
        }
        if(isEmpty(text.trim())) {
            showMsgInSeconds(setTextMsg, {err: 'Coloque un comentario', success: ''}, msgDuration)
            error = true
        } else if(isLength(text.trim(), 2)) {
            showMsgInSeconds(setTextMsg, {err: 'Debe tener al menos 5 caracteres', success: ''}, msgDuration)
            error = true
        } else if(text.trim().length > 200) {
            showMsgInSeconds(setTextMsg, {err: 'Máximo de 200 caracteres', success: ''}, msgDuration)
            error = true
        }
        return !error
    }

    return (
        <div id='contact' className='page'>
            <div className='top'>
                <Topbar />
                <Navbar />
                <div className='top-text-container'>
                    <div className='top-text'>
                        <h1>CONTÁCTANOS</h1>
                    </div>
                </div>
            </div>

            <section id='contact-1'>
                <div className='content'>
                    <div className='flexcol'>
                        <h2 className='title'>CONTACTO</h2>
                        <p className='text text-al-le'>Para una reunión técnica con la finalidad de conocer sus principales desafíos operacionales y de producción de su empresa, contáctanos a:</p>
                        <div className='subcontent'>
                            <div className='flexrow marg5 text al-it-ce'><FontAwesomeIcon icon="fa-solid fa-location-dot" /><p>Av. Javier Prado Este 5931 La Molina, Lima, Perú</p></div>
                            <div className='flexrow marg5 text al-it-ce'><FontAwesomeIcon icon="fa-solid fa-phone"/><p>(51–1) 6 849 477 / 6 933 775</p></div>
                            <div className='flexrow marg5 text al-it-ce'><FontAwesomeIcon icon="fa-solid fa-phone"/><p>(51) 987 768 358 </p></div>
                            <div className='flexrow marg5 text al-it-ce'><FontAwesomeIcon icon="fa-solid fa-phone"/><p>(51) 966 914 347 </p></div>
                            <div className='flexrow marg5 text al-it-ce'><FontAwesomeIcon icon="fa-solid fa-envelope"/><p>pmh@consultingpmh.com</p></div>
                        </div>
                        
                    </div>

                    <div className='flexcol'>
                        <p className='title'>ESCRÍBENOS</p>
                        <form>
                            <div className='flexcol marg5'>
                                <input type='text' id='name' name='name' value={name} placeholder='Ingrese su nombre' onChange={handleChange}/>
                                <Msg msg={nameMsg} align='left'/>
                            </div>
                            <div className='flexcol marg5'>
                                <input type='email' id='email' name='email' value={email} placeholder='Ingrese su email' onChange={handleChange}/>
                                <Msg msg={emailMsg} align='left'/>
                            </div>
                            <div className='flexcol marg5'>
                                <input type='text' id='phone' name='phone' value={phone} placeholder='Ingrese su número de celular' onChange={handleChange}/>
                                <Msg msg={phoneMsg} align='left'/>
                            </div>
                            <div className='flexcol marg5'>
                                <textarea cols='20' rows='4' type='text' id='text' name='text' placeholder='Agregue un comentario' value={text} onChange={handleChange}></textarea>
                                <Msg msg={textMsg} align='left'/>
                            </div>
                            <div>
                                <button type='submit' className='button' onClick={saveContact}>Enviar</button>
                                <Msg className="text" msg={sentMsg} align='center'/>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}