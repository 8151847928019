import React from 'react'
import './msg.css'

export const errMsg = (msg) => {
    return <div className="err">{msg}</div>
}

export const successMsg = (msg) => {
    return <div className="success">{msg}</div>
}

export const alertMsg = (msg) => {
    return <div className="alert">{msg}</div>
}

export const showMsgInSeconds = (set, msg, duration ) => {
    console.log(msg)
    if(!msg.err && !msg.success && !msg.alert) return
    set(msg)
    setTimeout(() => set({err: '', success: '', alert: ''}), duration*1000);
}

export default function Msg({msg, align}) {
    const {err, success, alert } = msg
    return (
        <div className='msg' style={{textAlign: align}}>
            {err && errMsg(err)}
            {success && successMsg(success)}
            {alert && alertMsg(alert)}
        </div>
    )
}
