import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import Topbar from '../Topbar'
import Modal from '../utils/modal/Modal'
import Footer from '../Footer'

import img1 from '../../assets/images/home-top-background.png'
import img2 from '../../assets/images/home-top-2-background.png'
import img3 from '../../assets/images/home-top-3-background.png'

import client1 from '../../assets/images/clientes/aum.png'
import client2 from '../../assets/images/clientes/boroo.png' //BROO
import client3 from '../../assets/images/clientes/buenaventura.png' //B
import client4 from '../../assets/images/clientes/igh.png'
import client5 from '../../assets/images/clientes/mochica.png' //M
import client6 from '../../assets/images/clientes/molle-verde.png'

import brochureESP from '../../assets/brochure_esp.pdf'
import brochureENG from '../../assets/brochure_esp.pdf' // Cambiar con el de ingles


const bgImages = [
    img1,
    img2,
    img3,
]

const intervalTimeInSecs = 7

export default function Home() {
    const [openModal, setOpenModal] = useState(false)
    useEffect(() => {
        let i = 0
        const interval = setInterval( ()=> {
            i = i + 1
            if(i === bgImages.length) i = 0
            changeBackground(i)
        }
        , intervalTimeInSecs * 1000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    const changeBackground = (i) => {
        const carrousel = document.getElementById('carrousel')
        carrousel.style.backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('${bgImages[i]}'`;
        carrousel.style.backgroundSize = 'cover'
    }

    const handleOpenModal = (e) => {
        setOpenModal(true)
    }
    
    const download = (lang) => {
        const link = document.createElement('a')
        link.href= lang === 'ESP' ? brochureESP : brochureENG
        link.download = `brochure_${lang}.pdf`
        link.click()
    }
    return (
        <div id="home" className='page'>
            <div className='top' id='carrousel'>
                <Modal open={openModal} close={()=>setOpenModal(false)}>
                    <div className='modal-content'>
                        <p className='title'>BROCHURE</p>
                        <p className='subtitle'>Seleccione el idioma</p>
                        <div className='flexcol w100 center'>
                            <button className='button' onClick={()=>download('ESP')}>ESP</button>
                            <button className='button' onClick={()=>download('ENG')}>ENG</button>                            
                        </div>
                    </div>
                </Modal>
                <Topbar />
                <Navbar />
                <div className='top-text-container'>
                    <div className='top-text'>
                        <h1>PM{'&'}H<br/>CONSULTING SAC</h1>
                        <p>CONSULTORIA ESPECIALIZADA EN PROCESAMIENTO DE MINERALES E HIDROMETALURGIA</p>
                        <div className='subcontent'>
                            <button className='button' onClick={handleOpenModal}>BROCHURE</button>
                        </div>
                    </div>
                </div>
            </div>
            <section id='home-1'>
                <div className='title-container'>
                    <h2 className='title'>PROYECTOS EN DESARROLLO</h2>
                </div>
                <div className='content'>
                    <div className='card'>
                        <div className='card-img-container'>
                            <img src={client5} alt='cliente 5'/>
                        </div>
                        <div className='flexcol w100'>
                            <h3>Evaluación Metalúrgica de Recuperación de Oro y Plata de Ripios de Lixiviación, Mina La Virgen</h3>
                            <h3>Diseño de Planta de Lixiviación de Ripios de Mina La Virgen, para Producción de Oro y Plata</h3>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-img-container'>
                            <img src={client3} alt='cliente 3'/>
                        </div>
                        <div className='flexcol w100'>
                            <h3>Diseño y Evaluación de Pruebas en Columnas de Lixiviación Clorurada para el Mineral del Proyecto Trapiche de Molle Verde</h3>
                            <h3>Asesoría Técnica en Metalurgia para el Desarrollo del Proyecto Trapiche de Molle Verde: Diseño de Pruebas Metalúrgicas, Estudio Conceptual, Diseño de Equipos y Soporte en Ingeniería, Construcción y EIA</h3>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-img-container'>
                            <img src={client2} alt='cliente 2'/>
                        </div>
                        <div className='flexcol w100'>
                            <h3>Asesoría Metalúrgica en las Operaciones de Lixiviación y Planta de Lagunas Norte</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id='home-2'>
                <div className='title-container'>
                    <h2 className='title'>NUESTROS CLIENTES</h2>
                </div>
                <div className='flexrow flexwrap center marg5'>
                    <img src={client1} alt='cliente 1'/>
                    <img src={client2} alt='cliente 2'/>
                    <img src={client3} alt='cliente 3'/>
                    <img src={client4} alt='cliente 4'/>
                    <img src={client5} alt='cliente 5'/>
                    <img src={client6} alt='cliente 6'/>
                </div>
            </section>

            <Footer />
        </div>
    )
}
