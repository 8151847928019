import React from 'react'

import logo from '../assets/images/logo-w.png'

export default function Footer() {
    return (
        <footer id='footer'>
            <img src={logo} alt='footer-logo'/>
        </footer>
    )
}
