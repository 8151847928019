import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Navbar from '../Navbar'
import Topbar from '../Topbar'
import Msg, { showMsgInSeconds } from '../msg/Msg'
import { isEmail, isEmpty, isLength } from '../utils/validation'
import Modal from '../utils/modal/Modal'
import Footer from '../Footer'

const msgDuration = 5;

const initial = {
    name: '',
    role: '',
    company: '',
    email: '',
}

const initialMsg = {
    err: '',
    success: ''
}

export default function Publications() {
    const [data, setData] = useState(initial)
    const {name, role, company, email} = data
    const [nameMsg, setNameMsg] = useState(initialMsg)
    const [emailMsg, setEmailMsg] = useState(initialMsg)
    const [roleMsg, setRoleMsg] = useState(initialMsg)
    const [companyMsg, setCompanyMsg] = useState(initialMsg)
    const [openModal, setOpenModal] = useState(false)
    const [sentMsg, setSentMsg] = useState(initialMsg)
    const [selected, setSelected] = useState([])
    const [publications, setPublications] = useState([])
    
    useEffect(() => {
        const getPubs = async() => {
            try {
                const res = await axios.get('/api/pubs/all')
                setPublications(res.data.pubs)
            } catch (err) {
                console.log(err)
            }
        }
        getPubs()
    }, [])
    

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value})
        setNameMsg(initialMsg)
        setEmailMsg(initialMsg)
        setRoleMsg(initialMsg)
        setCompanyMsg(initialMsg)
    }

    const sendEmail = async(e) => {
        e.preventDefault()
        if(validation()){
            setSentMsg({err: '', success: 'Solicitando ...'})
            try {
                const endppoint = '/api/pub-request-save'
                const res = await axios.post(endppoint , {
                    name, email, role, company
                })
                download(selected)
                showMsgInSeconds(setSentMsg, {err: '', success: res.data.msg}, msgDuration)
            } catch (err) {
                err.response.data.msg &&
                showMsgInSeconds(setSentMsg, {err: 'Error: Refresque(F5) y vuelva a intentarlo', success: ''}, msgDuration*3) 
            }
        }
    }

    const validation = () => {
        let error = false
        if(isEmpty(name.trim())) {
            showMsgInSeconds(setNameMsg, {err: 'Coloque su nombre', success: ''}, msgDuration)
            error = true
        } else if (isLength(name.trim(), 2)) {
            showMsgInSeconds(setNameMsg, {err: 'Debe tener al menos 2 caracteres', success: ''}, msgDuration)
            error = true
        }
        if(!isEmail(email)) {
            showMsgInSeconds(setEmailMsg, {err: 'El email es inválido', success: ''}, msgDuration)
            error = true
        }
        if(isEmpty(role.trim())) {
            showMsgInSeconds(setRoleMsg, {err: 'Coloque su cargo', success: ''}, msgDuration)
            error = true
        } else if (isLength(role.trim(), 2)) {
            showMsgInSeconds(setRoleMsg, {err: 'Debe tener al menos 2 caracteres', success: ''}, msgDuration)
            error = true
        }
        if(isEmpty(company.trim())) {
            showMsgInSeconds(setCompanyMsg, {err: 'Coloque el nombre de su empresa', success: ''}, msgDuration)
            error = true
        } else if (isLength(company.trim(), 2)) {
            showMsgInSeconds(setCompanyMsg, {err: 'Debe tener al menos 2 caracteres', success: ''}, msgDuration)
            error = true
        }
        return !error
    }

    const download = async(files) => {
        const indexes = files.map(file => file.split('_')[0]).join('-')
        await axios.get(`/api/pubs/selected/${indexes}`)
        window.location = `http://localhost:5000/api/pubs/selected/${indexes}`
    }

    const handleOpenModal = (e) => {
        setOpenModal(true)
    }

    const handleCheck = e => {
        const {id, checked} = e.target
        let array = [...selected]
        if(checked) {
            array.push(publications[id])
        } else {
            array = array.filter(el => el !== publications[id])
        }
        setSelected(array.sort())
    }

    return (
        <div id="publications" className='page'>
            <Modal open={openModal} close={()=>setOpenModal(false)}>
                <div className='modal-content'>
                    <p className='title'>Publicaciones ({selected.length})</p>
                    <p className='subtitle'>Complete la información</p>
                    <form id="pub-form">
                        <div className='input-group'>
                            <input type='text' id='name' name='name' value={name} placeholder='Nombre completo' onChange={handleChange}/>
                            <Msg msg={nameMsg} align='left'/>
                        </div>
                        <div className='input-group'>
                            <input type='email' id='email' name='email' value={email} placeholder='Email' onChange={handleChange}/>
                            <Msg msg={emailMsg} align='left'/>
                        </div>
                        <div className='input-group'>
                            <input type='text' id='company' name='company' value={company} placeholder='Compañía / Empresa' onChange={handleChange}/>
                            <Msg msg={companyMsg} align='left'/>
                        </div>
                        <div className='input-group'>
                            <input type='text' id='role' name='role' value={role} placeholder='Cargo / Ocupación' onChange={handleChange}/>
                            <Msg msg={roleMsg} align='left'/>
                        </div>
                        <div><button type='submit' className='button' onClick={sendEmail}>Solicitar {selected.length === 0 ? 'Publicación' : 'Publicaciones'}</button><Msg msg={sentMsg} align='center'/></div>
                    </form>
                </div>
            </Modal>
            <div className='top'>
                <Topbar />
                <Navbar />
                <div className='top-text-container'>
                    <div className='top-text'>
                        <h1>PUBLICACIONES</h1>
                    </div>
                </div>
            </div>

            <section id='pub-1'>
                <div className='title-container'>
                    <h2 className='title'>LISTA DE PUBLICACIONES</h2>
                </div>
                <div className='content'>
                    <div className='list-container'>
                        <ul>
                            {publications.map((pub, idx) => {
                                return (
                                    <li key={idx} className='text-link' ><input id={idx} type='checkbox' onChange={handleCheck}/> {pub.split('_')[1]}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='button-container'>{selected.length > 0 && <button className='button' onClick={handleOpenModal}>Solicitar</button>}</div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
